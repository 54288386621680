"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Password Manager
 * This is a password manager server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: test@te.st
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var UserWebAuthnCreateResponse_1 = require("./UserWebAuthnCreateResponse");
function UserWebAuthnCreateFromJSON(json) {
    return UserWebAuthnCreateFromJSONTyped(json, false);
}
exports.UserWebAuthnCreateFromJSON = UserWebAuthnCreateFromJSON;
function UserWebAuthnCreateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'response': UserWebAuthnCreateResponse_1.UserWebAuthnCreateResponseFromJSON(json['response']),
    };
}
exports.UserWebAuthnCreateFromJSONTyped = UserWebAuthnCreateFromJSONTyped;
function UserWebAuthnCreateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'response': UserWebAuthnCreateResponse_1.UserWebAuthnCreateResponseToJSON(value.response),
    };
}
exports.UserWebAuthnCreateToJSON = UserWebAuthnCreateToJSON;
