import { IDecryptionKeys } from '../backend/controller/credentialProviderPersist';

export interface IKeyInfo {
  id: number;
  displayName: string;
  lastUsed?: Date;
}

export default class PersistDecryptionKey {
  readonly storageDbName = "localDecryptionKey";
  readonly storageDbVersion = 3;
  readonly storageKeysName = "keys";
  readonly storageKeysCredentialIndex = "credentialId";
  readonly storageKeysUserIndex = "displayName";

  private db?: IDBDatabase;
  private debug?: (value: string) => void;

  constructor (debug?: (value: string) => void) {
    if (debug !== undefined) {
      this.debug = (value:string) => {debug("PersistDecryptionKey: "+ value)}
    }
  }

  private initStorage(): Promise<void> {
    return new Promise<void>((resolve_db, reject_db) => {
      const request = window.indexedDB.open(this.storageDbName, this.storageDbVersion);
      request.onerror = (e: any) => {
        this.debug?.(e);
        reject_db(e);
      };
      request.onsuccess = (event: any) => {
        if (event && event.target && "result" in event.target && event.target.result) {
          this.db = event.target.result;
          this.debug?.("db is setup");
          resolve_db();
        }
        else {
          this.debug?.("error when setting up db");
          reject_db();
        }
      };
      request.onupgradeneeded = (event: any) => {
        if (event && event.target && event.target.result) {
          this.debug?.(`Database upgrade needed: ${event.oldVersion} to ${event.newVersion}`);
          const db = event.target.result;
          const upgradeTransaction = event.target.transaction;
          let objectStore: IDBObjectStore;
          if (!db.objectStoreNames.contains(this.storageKeysName)) {
            this.debug?.(`Creating object store: ${this.storageKeysName}`);
            objectStore = db.createObjectStore(this.storageKeysName, { autoIncrement: true });
          }
          else {
            objectStore = upgradeTransaction.objectStore(this.storageKeysName);
          }
          if (!objectStore.indexNames.contains(this.storageKeysCredentialIndex)) {
            this.debug?.(`Creating index: ${this.storageKeysCredentialIndex}`);
            objectStore.createIndex(this.storageKeysCredentialIndex, "credentialIdString", {unique: true});
          }
          if (!objectStore.indexNames.contains(this.storageKeysUserIndex)) {
            this.debug?.(`Creating index: ${this.storageKeysUserIndex}`);
            objectStore.createIndex(this.storageKeysUserIndex, "displayName", {unique: true});
          }
        }
      };
    })
  }

  async activatePersistence(): Promise<void> {
    this.debug?.("starting activatePersistence");
    if (navigator.storage && navigator.storage.persist && !await navigator.storage.persisted()) {
      await navigator.storage.persist();
    }
  }

  async storeKeys(decryptionKeys: IDecryptionKeys): Promise<number> {
    this.debug?.("starting storeKeys");
    if (!this.db) {
      await this.initStorage();
    }
    await this.activatePersistence();
    return new Promise((resolve, reject) => {
      if (!this.db) {
        this.debug?.("database was not present");
        reject();
        return;
      }
      const transaction = this.db.transaction([this.storageKeysName], "readwrite");
      transaction.onerror = () => { 
        this.debug?.("error in transaction for store keys");
        reject(); 
      };
      const objectStore = transaction.objectStore(this.storageKeysName);
      const request = objectStore.add(decryptionKeys);
      request.onsuccess = (event: any) => {
        this.debug?.("storeKeys was successful");
        resolve(event.target.result);
      }
      request.onerror = (e: any) => { 
        this.debug?.("error in request for store keys");
        reject(); 
      };
    });
  }

  async removeKeys(id: number): Promise<void> {
    this.debug?.("starting removeKeys");
    if (!this.db) {
      await this.initStorage();
    }
    return new Promise<void>((resolve, reject) => {
      if (!this.db) {
        this.debug?.("database was not present");
        reject();
        return;
      }
      const transaction = this.db.transaction([this.storageKeysName], "readwrite");
      transaction.onerror = () => { 
        this.debug?.("error in transaction for removing keys");
        reject(); 
      };
      const objectStore = transaction.objectStore(this.storageKeysName);
      const request = objectStore.delete(id);
      request.onsuccess = () => {
        this.debug?.("removeKeys finished successfully");
        resolve();
      }
      request.onerror = () => {
        this.debug?.("removeKeys had an error");
        resolve();
      }
    });
  }

  async indexByCredentialId(credentialId: string): Promise<void|number> {
    this.debug?.("starting indexByCredentialId");
    if (!this.db) {
      await this.initStorage();
    }
    return new Promise<void|number>((resolve, reject) => {
      if (!this.db) {
        this.debug?.("database was not present");
        reject();
        return;
      }
      const transaction = this.db.transaction([this.storageKeysName], "readonly");
      transaction.onerror = () => {
        this.debug?.("error in transaction for indexByCredentialId");
        reject("indexdb transaction failed");
      }
      const objectStore = transaction.objectStore(this.storageKeysName);
      const index = objectStore.index(this.storageKeysCredentialIndex);
      const request = index.getKey(credentialId);
      request.onerror = () => resolve();
      request.onsuccess = () => resolve(request.result as number);
    });
  }

  // debug tool to dump indexeddb
  dumpIndexedDB(): void {
    const dbPromise = indexedDB.open("localDecryptionKey", 3);

    dbPromise.onerror = (event) => {
      console.log("oh no!");
    };
    dbPromise.onsuccess = (event: any) => {
      if (!(event && event.target && "result" in event.target && event.target.result)) {
        return;
      }
      const db = event.target.result;
      console.log(event);
      const transaction = db.transaction(["keys"]);
      const objectStore = transaction.objectStore("keys");
      const allItemsRequest = objectStore.getAll();

      allItemsRequest.onsuccess = () => {
        const all_items = allItemsRequest.result;
        this.debug?.(JSON.stringify(all_items));
      };
    };
  }

  async loadKeys(id: number): Promise<IDecryptionKeys> {
    this.debug?.("starting loadKeys");
    this.dumpIndexedDB();
    if (!this.db) {
      await this.initStorage();
    }
    return new Promise((resolve, reject) => {
      if (!this.db) {
        this.debug?.("database was not present");
        reject();
        return;
      }
      const transaction = this.db.transaction([this.storageKeysName], "readonly");
      transaction.onerror = () => {
        this.debug?.("error in transaction for loadKeys");
        reject();
      }
      const objectStore = transaction.objectStore(this.storageKeysName);
      const request = objectStore.get(id);
      request.onerror = (e) => {
        this.debug?.("error in request for loadKeys");
        reject(e);
      }
      request.onsuccess = () => resolve(request.result as IDecryptionKeys);
    });
  }

  async appendCredentialId(keyId: number, credentialId: ArrayBuffer, credentialIdString: string, displayName: string): Promise<void> {
    this.debug?.("starting appendCredentialId");
    //attention: this does not update the current key!
    if (!this.db) {
      await this.initStorage();
    }
    return new Promise<void>((resolve, reject) => {
      if (!this.db) {
        this.debug?.("database was not present");
        reject();
        return;
      }
      const transaction = this.db.transaction([this.storageKeysName], "readwrite");
      transaction.onerror = () => {
        this.debug?.("error in transaction for appendCredentialId");
        reject();
      }
      const objectStore = transaction.objectStore(this.storageKeysName);
      const openCursorResult = objectStore.openCursor(keyId);
      openCursorResult.onsuccess = (event: any) => {
        const cursor: IDBCursorWithValue = event.target.result;
        if (cursor) {
          const data = cursor.value;
          data.credentialId = credentialId;
          data.credentialIdString = credentialIdString;
          data.displayName = displayName;
          const updateRequest = cursor.update(data);
          updateRequest.onsuccess = () => {
            this.debug?.("appendCredentialId was successful");
            resolve();
          }
          updateRequest.onerror = (e) => {
            this.debug?.("updateRequest for appendCredentialId failed");
            reject(e);
          }
        }
      };
      openCursorResult.onerror = () => {
        this.debug?.("error in cursor opening for appendCredentialId");
        reject();
      }
    });
  }

  async getCredentialIds(): Promise<Array<ArrayBuffer>> {
    this.debug?.("starting getCredentialIds");
    if (!this.db) {
      await this.initStorage();
    }
    return new Promise<Array<ArrayBuffer>>((resolve, reject) => {
      if (!this.db) {
        this.debug?.("database was not present");
        reject();
        return;
      }
      const transaction = this.db.transaction([this.storageKeysName], "readonly");
      transaction.onerror = () => {
        this.debug?.("error in transaction for getCredentialIds");
        reject();
      }
      const objectStore = transaction.objectStore(this.storageKeysName);
      const request = objectStore.getAll();
      request.onerror = (e) => {
        this.debug?.("error in request for getCredentialIds");
        reject(e);
      }
      request.onsuccess = () => {
        const keysWithId = request.result.filter(o => Object.prototype.hasOwnProperty.call(o, 'credentialId'));
        resolve(keysWithId.map(k => k.credentialId));
      }
    });
  }

  async clearAll(): Promise<void> {
    this.debug?.("starting clearAll");
    if (!this.db) {
      await this.initStorage();
    }
    return new Promise<void>((resolve, reject) => {
      if (!this.db) {
        this.debug?.("database was not present");
        reject();
        return;
      }
      const transaction = this.db.transaction([this.storageKeysName], "readwrite");
      transaction.onerror = () => {
        this.debug?.("error in transaction for clearAll");
        reject();
      }
      const objectStore = transaction.objectStore(this.storageKeysName);
      const request = objectStore.clear();
      request.onsuccess = () => resolve();
    });
  }

  async getKeyList(): Promise<Array<IKeyInfo>> {
    this.debug?.("starting getKeyList");
    if (!this.db) {
      await this.initStorage();
    }
    return new Promise<Array<IKeyInfo>>((resolve, reject) => {
      if (!this.db) {
        this.debug?.("database was not present");
        reject();
        return;
      }
      const transaction = this.db.transaction([this.storageKeysName], "readonly");
      transaction.onerror = () => {
        this.debug?.("error in transaction for getKeyList");
        reject("indexdb transaction failed");
      }
      const objectStore = transaction.objectStore(this.storageKeysName);
      const index = objectStore.index(this.storageKeysCredentialIndex);

      const result: Array<IKeyInfo> = [];
      index.openCursor().onsuccess = (event: any) => {
        const cursor: IDBCursorWithValue = event.target.result;
        if (cursor) {
          const data = cursor.value;
          result.push({ id: cursor.primaryKey as number, displayName: data.displayName, lastUsed: data.lastUsed});
          cursor.continue();
        } else {
          this.debug?.(`resolve getKeyList with ${result.length} entries`);
          resolve(result);
        }
      };
      index.openCursor().onerror = (e) => {
        if (e instanceof Error) {
          this.debug?.(`getKeyList error: ${e.message}`);
        }
        reject(e);
      };
    });
  }

  async setLastUsed(keyId: number, lastUsed: Date): Promise<void> {
    this.debug?.("starting setLastUsed");
    if (!this.db) {
      await this.initStorage();
    }
    return new Promise<void>((resolve, reject) => {
      if (!this.db) {
        this.debug?.("database was not present");
        reject();
        return;
      }
      const transaction = this.db.transaction([this.storageKeysName], "readwrite");
      transaction.onerror = () => {
        this.debug?.("error in transaction for setLastUsed");
        reject();
      }
      const objectStore = transaction.objectStore(this.storageKeysName);
      objectStore.openCursor(keyId).onsuccess = (event: any) => {
        const cursor: IDBCursorWithValue = event.target.result;
        if (cursor) {
          const data = cursor.value;
          data.lastUsed = lastUsed;
          const updateRequest = cursor.update(data);
          updateRequest.onsuccess = () => resolve();
          updateRequest.onerror = (e) => reject(e);
        }
      };
    });
  }
}
