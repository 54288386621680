"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Password Manager
 * This is a password manager server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: test@te.st
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function UserWebAuthnLogonResultFromJSON(json) {
    return UserWebAuthnLogonResultFromJSONTyped(json, false);
}
exports.UserWebAuthnLogonResultFromJSON = UserWebAuthnLogonResultFromJSON;
function UserWebAuthnLogonResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'success': json['success'],
        'message': json['message'],
        'lastLogin': !runtime_1.exists(json, 'lastLogin') ? undefined : (json['lastLogin'] === null ? null : new Date(json['lastLogin'])),
        'failedLogins': !runtime_1.exists(json, 'failedLogins') ? undefined : json['failedLogins'],
        'decryptionKey': json['decryptionKey'],
    };
}
exports.UserWebAuthnLogonResultFromJSONTyped = UserWebAuthnLogonResultFromJSONTyped;
function UserWebAuthnLogonResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'success': value.success,
        'message': value.message,
        'lastLogin': value.lastLogin === undefined ? undefined : (value.lastLogin === null ? null : value.lastLogin.toISOString()),
        'failedLogins': value.failedLogins,
        'decryptionKey': value.decryptionKey,
    };
}
exports.UserWebAuthnLogonResultToJSON = UserWebAuthnLogonResultToJSON;
